import React, {useState} from "react";
import {useRouter} from "next/router";
import Script from 'next/script';

// Html Parser
import parse from 'html-react-parser';
import dynamic from 'next/dynamic';
// scss
import styles from "./faq.module.scss";

const FaqRowItem = dynamic(() => import('./faqRowItem'));

const Faq = (props) => {

  const { faqData } = props;
  const getRandomString = (length) => {
    const randomChars = 'abcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  let faqDataArr = [];
  let faqTitle = '';
  
  if (faqData) {
    let myArray = [];
    let i = 1;
    if (faqData.faq) {
      (faqData.faq).forEach(element => {
        let uniqueStr;
        if (i === 1) {
          uniqueStr = "aaaaa";
        }
        else {
          uniqueStr = getRandomString(5).toLowerCase();
        }
        myArray.push({ uuid: uniqueStr, question: element.question, answer: element.answer });
        i++;
      });
    }
    faqDataArr = myArray;

    if (faqData) {
      let convTitle = '';

      if(faqData.faq_title) {
        convTitle = faqData.faq_title;
      } else if (faqData.page_contents && faqData.page_contents.name) {
        convTitle = parse(faqData.page_contents.name);
      }

      if (convTitle && convTitle.props && convTitle.props.children) {
        faqTitle = convTitle.props.children;
      } else {
        faqTitle = faqData.faq_title && faqData.faq_title !== '' && faqData.faq_title !== null ? faqData.faq_title : '';
      }
    }
  }
  let faqSchema = '';
  
  if (faqDataArr && faqDataArr.length > 0) {
    faqSchema = `
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "name": "FAQs",
      "mainEntity": [
        ${faqDataArr.map(element => {
        const textAns = ((element.answer).replace(/"/g, '&quot;')).replace(/<(.|\n)*?>/g, '');
        return `{
          "@type": "Question",
          "name": "${(element.question).replace(/"/g, '&quot;')}",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "${textAns}"
          }
        }`;
      })}
      ]
    }
  `;
  }

  const router = useRouter();
  const [selectedItem, setSelectedItem] = useState(0);

  function toggleItem(e, index) {
    if(e.currentTarget.classList.contains(styles.show)) {
      setSelectedItem(-1);
    } else {
      setSelectedItem(index)
    }
  }

  return (
    <>
      {faqSchema !== '' && router && router.asPath !== '/faqs' && <Script id="faq-schema" type="application/ld+json" dangerouslySetInnerHTML={{ __html: faqSchema }} strategy="beforeInteractive" /> }
      {props.faqData.faq.length > 0 || (faqTitle && faqTitle !== '') ?
        <section className={`${styles.faq} pt-0 ${props.className}`} id="faq">
        <div className="container">
          {router.asPath === '/faqs' ? "" :
            <div className="text-center">
              {/* <h2 className="section-title text-capitalize">{faqTitle && parse(faqTitle)} F.A.Q.</h2> */}
              <h2 className="section-title text-capitalize">{faqTitle && parse(faqTitle) ? parse(faqTitle) : ''}</h2>
            </div>
          }

          <div className={styles.faqAccordion}>
            <div className={`${styles.accordion} ${router.asPath === "/faqs" ? "" : "row"}`}>
              {
                (faqData && faqData.faq && faqData.faq.length > 0) ?
                  <>
                  {router.asPath === "/faqs" ? 
                    faqData.faq.map((item, index) => <FaqRowItem key={index} index={index} item={item}
                                                                 toggleItem={toggleItem} selectedItem={selectedItem} /> )
                   : 
                    <>
                      <div className={`${styles.accordion__itemWrapper} col-md-6`}>
                        {props.faqData.faq.length % 2 === 1 ? 
                          props.faqData.faq.map((item, index) => {
                            return index <= Math.floor(faqData.faq.length / 2)
                                && <FaqRowItem key={index} index={index} item={item}
                                               toggleItem={toggleItem} selectedItem={selectedItem} />
                            }
                          )
                         :
                          faqData.faq.map((item, index) => {
                            return index < Math.floor(faqData.faq.length / 2)
                                && <FaqRowItem key={index} index={index} item={item}
                                               toggleItem={toggleItem} selectedItem={selectedItem} />
                            }
                          )
                        }
                      </div>
                      {faqData.faq && faqData.faq.length > 1 ?
                        <div className={`${styles.accordion__itemWrapper} col-md-6`}>
                          {faqData.faq.length % 2 === 1 ?
                            faqData.faq.map((item, index) => {
                              return index > Math.floor(faqData.faq.length / 2) && index < props.faqData.faq.length
                                  && <FaqRowItem key={index} item={item} index={index}
                                                 toggleItem={toggleItem} selectedItem={selectedItem} />
                            })
                          : 
                            faqData.faq.map((item, index) => {
                              return index >= Math.floor(faqData.faq.length / 2) && index < faqData.faq.length
                                  && <FaqRowItem key={index} item={item} index={index}
                                                 toggleItem={toggleItem} selectedItem={selectedItem} />
                            })
                          }
                        </div>
                      : null}
                    </>
                   }
                  </>
                  :
                  null
              }
            </div>
          </div>
        </div>
      </section>
      : ''}
    </>
  );
}

export default Faq;